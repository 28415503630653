/* eslint-disable react/no-danger */
import 'antd/dist/antd.css';
// CSS for react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@source/i18next';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'styled-components';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useEffect, useMemo, useState } from 'react';
import jsCookie from 'js-cookie';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { THEME, THEME_HK, THEME_JP } from '@source/constants/theme';
import RegionProvider from '@source/components/RegionProvider';
import ModalProvider from '@source/components/ModalProvider';
import MaintenanceProvider from '@source/components/MaintenanceProvider';
import { wrapper } from '@source/redux';
import ErrorBoundary from '@source/components/ErrorBoundaries';
import { saveReferrer, sentryUnhandledRejectionFormatter } from '@source/utils/utils';
import useGTMEvent from '@source/hooks/useGTMEvent';
import HeadScript from '@source/components/Vendors/HeadScript';
import {
  COOKIE_KEYS,
  WS_USER_ACCESS_TOKEN_KEY,
  WS_USER_PROFILE_COUNTRY_KEY,
  WS_USER_PROFILE_KEY,
  isProduction,
  WS_USER_CONTACT_ID_KEY,
} from '@source/constants/common';
import useUserProfileServices from '@source/hooks/useUserProfileServices';
import { IProfileResponsePromise } from '@source/interface/userProfile';
import { TRegionKey } from '@source/interface';
import { NEXT_PUBLIC_SENTRY_DSN, NEXT_PUBLIC_SENTRY_ENVIRONMENT } from '@source/constants/env';
import { commonSeoSchemaOrganization, commonSeoSchemaWebsite } from '@source/constants/seo';

require('@source/assets/fonts.css');
require('@source/constants/app.less');

const queryClient = new QueryClient();

interface GTMObjProps {
  event: string;
  page_url: string;
  page_pathname: string;
  price?: number;
  listing_status?: string;
  make?: string;
  model?: string;
  year?: string;
  body_style?: string;
  transmission?: string;
  fuel_type?: string;
  drivetrain?: string;
  state_of_vehicle?: string;
  user_id?: string;
}

function MyApp({
  Component,
  pageProps,
}: AppProps & {
  Component: AppProps['Component'] & {
    pageReady?: boolean;
  };
}) {
  const { pushGTMEvent } = useGTMEvent();
  const { userProfileState, fetchProfile, setUserFromCookie, saveUserToCookie } = useUserProfileServices();
  const { profile, loaded } = userProfileState;
  const appProps: any = pageProps;
  const [pageReady, setPageReady] = useState(Component.pageReady ?? true);

  const countryCookie = jsCookie.get(COOKIE_KEYS.STORED_REGION);
  const profileCountry = jsCookie.get(WS_USER_PROFILE_COUNTRY_KEY);
  const userContactId = jsCookie.get(WS_USER_CONTACT_ID_KEY);

  if (isProduction) {
    Sentry.init({
      dsn: NEXT_PUBLIC_SENTRY_DSN,
      normalizeDepth: 5,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.5, // lower the value in production
      environment: NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'development',
      beforeSend: (event, hint) => {
        // customize unhandledRejection issue
        const customEvent = sentryUnhandledRejectionFormatter(event, hint);
        return customEvent || event;
      },
    });
  }

  const onPageReady = () => {
    setPageReady(true);
  };

  useEffect(() => {
    saveReferrer();

    let gtmObj: GTMObjProps = {
      event: 'PAGE_VIEW',
      page_url: window.location.href,
      page_pathname: window.location.pathname,
    };

    if (appProps?.carDetails?.carInfoSection?.price && appProps?.carDetails?.flagSection) {
      const { isAvailable, isPendingSale, isComingSoon, isSold } = appProps.carDetails.flagSection;
      gtmObj = {
        ...gtmObj,
        state_of_vehicle: 'used',
        make: appProps?.carDetails?.inspectionSection?.make,
        model: appProps?.carDetails?.inspectionSection?.model,
        year: appProps?.carDetails?.inspectionSection?.yearOfManufacture,
        body_style: appProps?.carDetails?.overViewSection?.bodyType,
        transmission: appProps?.carDetails?.overViewSection?.inventory?.transmission,
        fuel_type: appProps?.carDetails?.overViewSection?.inventory?.fuel_type,
        price: appProps?.carDetails?.carInfoSection?.price,
        listing_status:
          (isSold && 'sold') ||
          (isPendingSale && 'pending_sale') ||
          (isComingSoon && 'coming_soon') ||
          (isAvailable && 'available') ||
          '',
      };
    }

    if (userContactId) {
      gtmObj = { ...gtmObj, user_id: userContactId };
    }

    pushGTMEvent(gtmObj);
  }, []);

  // Get user information from cookie and save to `userProfile` state when first load app
  useEffect(() => {
    const cookiesJSON = jsCookie.get();

    const cookieProfile =
      typeof cookiesJSON[WS_USER_PROFILE_KEY] === 'string'
        ? JSON.parse(cookiesJSON[WS_USER_PROFILE_KEY])
        : cookiesJSON[WS_USER_PROFILE_KEY];

    // When change country and the previous profile data is not from current country
    if (profileCountry && countryCookie && profileCountry !== countryCookie) {
      jsCookie.remove(WS_USER_PROFILE_KEY);
      jsCookie.remove(WS_USER_ACCESS_TOKEN_KEY);
      jsCookie.remove(WS_USER_CONTACT_ID_KEY);
      setUserFromCookie();
    }

    if (cookieProfile && profileCountry && countryCookie && profileCountry === countryCookie) {
      setUserFromCookie();
    }
  }, [countryCookie, profileCountry]);

  // The data was saved in above userEffect is not full information (when logged in)
  // Call `fetchProfile` to collect more information and save to `userProfile` state again
  useEffect(() => {
    if (profile?.slug && !loaded) {
      const fetchUserProfile = async () => {
        const res = (await fetchProfile({ country: (countryCookie as TRegionKey) || 'sg', slug: profile?.slug })) as {
          payload: IProfileResponsePromise;
        };
        const { data } = res?.payload || {};

        // eslint-disable-next-line @typescript-eslint/no-unused-vars, camelcase
        if (data) {
          // eslint-disable-next-line camelcase, @typescript-eslint/no-unused-vars
          const { tickets, profile_image, ...needProfileCookieData } = data || {};
          const newProfile = { ...profile, ...needProfileCookieData };
          saveUserToCookie({ profile: newProfile, profile_country: countryCookie });
        }
      };

      fetchUserProfile();
    }
  }, [JSON.stringify(profile), loaded]);

  const { lang, region } = appProps || {};

  const theme = useMemo(() => {
    if ((region === 'hk' && lang !== 'en') || (region === 'my' && lang?.toLowerCase() === 'zh-my')) {
      return THEME_HK;
    }

    if (region === 'jp' && lang !== 'en') {
      return THEME_JP;
    }

    return THEME;
  }, [lang, region]);

  return (
    <>
      <Head>
        {/** https://github.com/vercel/next.js/blob/master/errors/no-document-viewport-meta.md */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, shrink-to-fit=no"
        />
        {['sg', 'th', 'id', 'my'].includes(appProps?.region) && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(commonSeoSchemaWebsite),
            }}
          />
        )}
        {['sg', 'th', 'id', 'my'].includes(appProps?.region) && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(commonSeoSchemaOrganization),
            }}
          />
        )}
      </Head>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <RegionProvider>
            <ErrorBoundary region={countryCookie as TRegionKey}>
              <MaintenanceProvider region={countryCookie as TRegionKey}>
                <Component {...pageProps} onPageReady={onPageReady} pageReady={pageReady} />
              </MaintenanceProvider>
              {pageReady && <HeadScript />}
              {/* Can consider to manage all Modals/Drawers in ModalProvider */}
              <ModalProvider />
            </ErrorBoundary>
          </RegionProvider>
          <ReactQueryDevtools />
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default wrapper.withRedux(MyApp);
