import { API_URL } from '@source/constants/api';
import { TRegionKey } from '@source/interface';
import {
  ICreatePaymentGateway,
  IPaymentIntentProps,
  IRemoveSelectedFileTC,
  ISendAcceptTC,
  ISendFinanceOptionTC,
  ISendFinishUploadCash,
  ISendOfferTC,
  ISendUploadDoc,
} from '@source/interface/offer';
import { getAccessToken, getAxiosInstance } from '@source/utils/utils';

const customHeaders = {
  Accept: 'application/json',
};

export const WHOLESALE_AXIOS_USER_AUTH = getAxiosInstance(`${process.env.WHOLESALE_URL}/api/v1`, getAccessToken);

export const WHOLESALE_AXIOS_PAYMENT_AUTH = getAxiosInstance(
  `${process.env.WHOLESALE_URL}/api/v1`,
  getAccessToken,
  undefined,
  undefined,
  customHeaders,
);

export default {
  listOffer: (country: TRegionKey) => {
    const offerListAPI = `${API_URL(country).captainGo}/rabbit/${country}/data-query/offers`;
    return WHOLESALE_AXIOS_USER_AUTH.get(offerListAPI);
  },

  getListUploadOptions: (country: TRegionKey) => {
    const listUploadOptions = `${API_URL(country).captainGo}/rabbit/${country}/offers/config/optional-documents`;
    return WHOLESALE_AXIOS_USER_AUTH.get(listUploadOptions);
  },

  getOffer: (country: TRegionKey, offerId: number) => {
    const offersDetailAPI = `${API_URL(country).captainGo}/rabbit/${country}/offers/${offerId}`;

    return WHOLESALE_AXIOS_USER_AUTH.get(offersDetailAPI);
  },

  getOfferWorkFlow: (country: TRegionKey, offerId: number) => {
    const offerWorkFlowAPI = `${API_URL(country).captainGo}/rabbit/${country}/offers/${offerId}/workflow`;

    return WHOLESALE_AXIOS_USER_AUTH.get(offerWorkFlowAPI);
  },

  getOfferBuyer: (country: TRegionKey, offerId: number) => {
    const offerBuyerAPI = `${API_URL(country).captainGo}/rabbit/${country}/offers/${offerId}/buyer`;

    return WHOLESALE_AXIOS_USER_AUTH.get(offerBuyerAPI);
  },

  getOfferDepositDocs: (country: TRegionKey, offerId: number) => {
    const OfferDepositDocsAPI = `${API_URL(country).captainGo}/rabbit/${country}/offers/${offerId}/agreement-documents`;

    return WHOLESALE_AXIOS_USER_AUTH.get(OfferDepositDocsAPI);
  },

  getOfferVSO: (country: TRegionKey, offerId: number) => {
    const offerVSOapi = `${API_URL(country).captainGo}/rabbit/${country}/offers/${offerId}/vso`;

    return WHOLESALE_AXIOS_USER_AUTH.get(offerVSOapi);
  },

  getOfferPaymentSummary: (country: TRegionKey, offerId: number) => {
    const offerPaymentSummaryAPI = `${API_URL(country).captainGo}/rabbit/${country}/offers/${offerId}/payment-summary`;

    return WHOLESALE_AXIOS_USER_AUTH.get(offerPaymentSummaryAPI);
  },

  getLoanResult: (country: TRegionKey, offerId: number) => {
    const getLoanResultlAPI = `${API_URL(country).captainGo}/rabbit/${country}/offers/${offerId}/loan-result`;

    return WHOLESALE_AXIOS_USER_AUTH.get(getLoanResultlAPI);
  },

  getUploadedDocmuents: (country: TRegionKey, offerId: number) => {
    const currentDocmuentsAPI = `${API_URL(country).captainGo}/rabbit/${country}/offers/${offerId}/documents`;
    return WHOLESALE_AXIOS_USER_AUTH.get(currentDocmuentsAPI);
  },

  createPaymentIntent: ({ slug, data }: IPaymentIntentProps) => {
    const paymentIntentAPI = `${API_URL('my').captainGo}/rabbit/my/offers/${slug}/deposit/card/payment-intents`;
    return WHOLESALE_AXIOS_PAYMENT_AUTH.post(paymentIntentAPI, data);
  },

  sendAcceptTC: ({ slug, payload }: ISendAcceptTC) => {
    const sendAcceptTCPayload = `${API_URL('my').captainGo}/rabbit/my/offers/${slug}/accept-terms`;
    return WHOLESALE_AXIOS_USER_AUTH.post(sendAcceptTCPayload, payload);
  },

  sendAcceptOffer: ({ slug }: ISendOfferTC) => {
    const sendAcceptTCPayload = `${API_URL('my').captainGo}/rabbit/my/offers/${slug}/accept`;
    return WHOLESALE_AXIOS_USER_AUTH.post(sendAcceptTCPayload);
  },

  sendFinishUploadCash: ({ slug }: ISendFinishUploadCash) => {
    const sendFinishUploadCashPayload = `${API_URL('my').captainGo}/rabbit/my/offers/${slug}/financing-and-insurance`;
    return WHOLESALE_AXIOS_USER_AUTH.post(sendFinishUploadCashPayload);
  },

  removeSelectedFile: ({ slug, fileId }: IRemoveSelectedFileTC) => {
    const removeSelectedFilePayload = `${API_URL('my').captainGo}/rabbit/my/offers/${slug}/documents/${fileId}`;
    return WHOLESALE_AXIOS_USER_AUTH.delete(removeSelectedFilePayload);
  },

  sendFinanceOption: ({ slug, payload }: ISendFinanceOptionTC) => {
    const sendAcceptTCPayload = `${API_URL('my').captainGo}/rabbit/my/offers/${slug}/choose-financing-option`;
    return WHOLESALE_AXIOS_USER_AUTH.post(sendAcceptTCPayload, payload);
  },

  createPaymentGateway: ({ slug, payload }: ICreatePaymentGateway) => {
    const createPaymentGatewayURL = `${API_URL('my').captainGo}/rabbit/my/offers/${slug}/hsbc/create-payment`;
    return WHOLESALE_AXIOS_PAYMENT_AUTH.post(createPaymentGatewayURL, payload);
  },

  sendUploadDoc: ({ offerId, payload }: ISendUploadDoc) => {
    const sendUploadDocPayload = `${API_URL('my').captainGo}/rabbit/my/offers/${offerId}/documents`;
    return WHOLESALE_AXIOS_USER_AUTH.post(sendUploadDocPayload, payload);
  },

  fetchFPXPaymentIntent: ({ slug, data }: IPaymentIntentProps) => {
    const paymentFPXIntentAPI = `${API_URL('my').captainGo}/rabbit/my/offers/${slug}/deposit/fpx/payment-intents`;
    return WHOLESALE_AXIOS_PAYMENT_AUTH.post(paymentFPXIntentAPI, data);
  },
};
